import React, { useEffect } from "react";
import PreLoginLayout from "components/PageLayout/PreloginLayout";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import { t1, t3 } from "translate";
import FormCreator from "commons/final-form/core/FormCreator";
import loginLayout from "schema-form/layout/loginLayout";
import loginSchema from "schema-form/schema/loginSchema";
import loginUI from "schema-form/ui/loginUI";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { loginAction, setProfileAction } from 'redux/actions';
import { connect } from 'react-redux';
import Divider from 'antd/lib/divider';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from "react-router-dom";
import { getQueryParams } from 'helper/helper';
import MobileDetect from 'mobile-detect'
import logoFB from 'assets/icons/common/logo-facebook.svg';
import logoGG from 'assets/icons/common/logo-google.svg';

const BackgroundStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0, 0.5)'
}

const LoginLayout = ({dispatch}) => {
    const navigate = useNavigate();
    const reloadProfile = () => {
        upParamsprofile('');
        upParamsprofile({submit:1})
      }
	const location = useLocation();
    const { token = '' } = getQueryParams(location.search, ['token']);

    useEffect(() => {
        if (!!token) {
            var md = new MobileDetect(window.navigator.userAgent);
            if (md.mobile()) {
                const redirectUrl = window.MOBILE_SERVER + '/login?token=' + token;
                window.location.replace(redirectUrl);
            } else {
                dispatch(loginAction(token));
                reloadProfile();
                message.success(t1('login_successful'))
            }
        }
    }, [token]);
    
      const [dataprofile, upEndpointsprofile, upParamsprofile, updateMethod, updateAuth] = useFetch(
          endpoints.get_profile,
          {},
          'get',
          (result,params) => {
              dispatch(setProfileAction({...result?.data,reloadProfile: reloadProfile}));
          },
          (result, params) => {
          }
      );

    const [data, UpEnpoints, setLoginParams] = useFetch(
        endpoints.login,
        "",
        'post',
        (result, params) => {
            dispatch(loginAction(result?.data?.token));
            updateAuth(result?.data?.token);
            reloadProfile();
            message.success(t1('login_successful'));
            const previousPath = Cookies.get('previous_path');
            if (!!previousPath) {
                navigate(previousPath, { replace: true })
                Cookies.remove('previous_path');
            }
        },
        (result, params) => {
            if (!result?.success) {
                message.error(result?.message || 'Login failed');
            }
        }
    );

    return (
        <PreLoginLayout>
            <div style={BackgroundStyle}></div>
            <Row>
                <Col span={24} md={{ span: 4, offset: 10 }} xs={{ span: 22, offset: 1 }}>
                    <Card
                        title={null}
                        bordered={true}
                        style={{ width: "100%", marginTop: "200px" }}
                    >
                        <h3 className="text-center mb-2 mt-3">{t3('login_panel')}</h3>
                        <p className="text-center mb-4">{t1('login_panel_description')}</p>
                        <FormCreator
                            debugMode={false}
                            schema={loginSchema}
                            ui={loginUI}
                            layout={loginLayout}
                            onSubmit={(value) => {
                                setLoginParams(value);
                            }}
                            SubmitButton={(form) => {
                                return <Button
                                    type="primary"
                                    style={{ width: "100%" }}
                                    onClick={(e) => form.submit()}
                                >
                                    {t1("click_to_login")}
                                </Button>;
                            }}
                        />
                        <Divider />
                        <Button
                            style={{ width: "100%" }}
                            onClick={(e) =>
                                (window.location = "/register")
                            }
                        >
                            {t1("click_to_register")}
                        </Button>
                    </Card>
                </Col>
            </Row>
        </PreLoginLayout>
    );
};

export default connect()(LoginLayout);
